import React, { FC } from 'react';

import { safeGet } from '../../../helpers/utils';

import {
  AdBackground,
  AdPosition,
  BackgroundSpacing,
  StyledAdSlot,
} from './DFPContainer.styled';
import SmartSessionStorage from '../../../helpers/storage/SmartSessionStorage';
import { DFPProps, SizeMapping, UnitProps } from './DFPContainer.types';

type minHeightMap = {
  viewWidth: number;
  minHeightObj: { width: number; height: number };
};
type minHeightMapArray = minHeightMap[];

const getDfpTiling = () => {
  if (typeof window !== 'undefined') {
    if (SmartSessionStorage.get('tilingValue')) {
      return SmartSessionStorage.get('tilingValue');
    }

    // https://distilledsch.tpondemand.com/restui/board.aspx?#page=userstory/60922
    // We need this random value to persist with the user session and be sent as a tiling targeting value
    // This is an important requirement and will cause impact to revenue from ad spots when it is missing
    const value = Math.floor(Math.random() * 10).toString();

    SmartSessionStorage.set('tilingValue', value);
    return value;
  }
};

export const getTargetingArguments = (
  targetingArguments: {
    [key: string]: string | [string];
  } = {},
  debugMode = false,
) => {
  const updatedArgs = { ...targetingArguments, tiling: getDfpTiling() };
  if (debugMode) {
    return { ...updatedArgs, site: 'debugmode' };
  }
  return { ...updatedArgs };
};

// This ensures no unit is displayed until the first declaration of one
const generateSizeMap = (sizeMapping: SizeMapping[]) => [
  ...sizeMapping,
  {
    viewport: [0, 0] as [number, number],
    sizes: [],
  },
];

export const DFPUnit: FC<UnitProps> = ({
  styles = {},
  sizeMapping,
  dfpDisabled,
  AdSlot,
  disableBackground = false,
  ...rest
}) => {
  if (dfpDisabled) {
    return null;
  }

  const currentSizeMap = generateSizeMap(sizeMapping);

  const minHeightMapArray = currentSizeMap.reduce(
    (acc: minHeightMapArray, sizeMap) => {
      const [viewWidth] = sizeMap.viewport;
      const currentSizes = safeGet(sizeMap, ['sizes']);
      let minHeightObj = { width: 0, height: 0 };

      if (currentSizes) {
        minHeightObj = currentSizes.reduce(
          (
            targetHeightObj: { width: number; height: number },
            adSize: [number, number],
          ) => {
            const [width, height] = adSize;
            return height > targetHeightObj.height
              ? { width, height }
              : targetHeightObj;
          },
          minHeightObj,
        );
      }

      return [
        ...acc,
        {
          viewWidth,
          minHeightObj: {
            ...minHeightObj,
          },
        },
      ];
    },
    [],
  );

  return (
    <StyledAdSlot {...styles} data-testid={`dfp-slot-${rest.slotId}`}>
      <AdPosition
        margin={styles.margin}
        padding={styles.padding}
        justifyContent={styles.justifyContent}
      >
        <AdBackground
          minHeightMapArray={minHeightMapArray}
          disableBackground={disableBackground}
        >
          <AdSlot sizeMapping={currentSizeMap} {...rest} />
          <BackgroundSpacing
            disableBackground={disableBackground}
          ></BackgroundSpacing>
        </AdBackground>
      </AdPosition>
    </StyledAdSlot>
  );
};

export const setDFPProps = (
  { targetingArguments, dfpNetworkId, collapseEmptyDivs = true }: DFPProps,
  debugMode = false,
) => ({
  dfpNetworkId,
  collapseEmptyDivs,
  targetingArguments: getTargetingArguments(targetingArguments, debugMode),
});

export const DFPRefresh = (googletag: string, DFPManager: any) => {
  if (googletag) {
    DFPManager.refresh();
  }
};

export const DFPLoad = (DFPManager: any) => {
  DFPManager.load();
};
