import { SearchPropertyCard } from '../../../../daft/types';

type SchemaPriceList = number[] | null | undefined;

export const getPropertyListingSchemaLD = (
  listings: SearchPropertyCard[],
  metaTitle: string,
) => {
  // get all listings and map destructured values into new array of objects
  const combineAllListings = listings.map((result: SearchPropertyCard) => {
    const {
      listing: {
        price,
        id,
        title,
        numBedrooms,
        media,
        numBathrooms,
        propertyType,
        seoFriendlyPath,
      },
    } = result;

    return {
      price,
      id,
      title,
      numBedrooms,
      media,
      numBathrooms,
      propertyType,
      seoFriendlyPath,
    };
  });

  // mapping all prices from object array to be displayed in min/max price schema values
  const sortedPriceList =
    combineAllListings &&
    combineAllListings.length > 0 &&
    combineAllListings
      .map((item) => extractEuroPriceFromString(item?.price)) // Extract numbers from price strings
      .filter((price) => price !== null) // Filter out null (invalid prices or POA)
      .sort((a, b) => a - b); // Sort prices in ascending order

  // remove the end of the metaTitle for use in SEO name
  const pageTitle = metaTitle.replace('| Daft.ie', '');

  // get the currency from the first array to set the correct currency in the priceCurrency schema prop
  const priceCurrency = combineAllListings[0]?.price.includes('€');

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: pageTitle,
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: getListingMinPrice(sortedPriceList),
      highPrice: getListingsMaxPrice(sortedPriceList),
      priceCurrency: priceCurrency ? 'EUR' : 'GBP',
      availability: 'In Stock',
    },
  });
};

export const extractEuroPriceFromString = (priceString) => {
  if (!priceString || typeof priceString !== 'string') return null;

  // Check if the string contains the Euro symbol (€)
  const euroMatch = priceString.match(/€[\d,]+/);

  if (euroMatch) {
    // Remove the Euro symbol and commas, then convert to number
    const euroPrice = euroMatch[0].replace(/[€,]/g, '');
    return Number(euroPrice);
  }

  // If no Euro price found, return null
  return null;
};

// Function to get the minimum price
export const getListingMinPrice = (
  sortedPriceList: SchemaPriceList,
): number | null => {
  return sortedPriceList?.length ? sortedPriceList[0] : null;
};

// Function to get the maximum price
export const getListingsMaxPrice = (
  sortedPriceList: SchemaPriceList,
): number | null => {
  return sortedPriceList?.length
    ? sortedPriceList[sortedPriceList.length - 1]
    : null;
};
